$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0px;
  position: relative;
  margin-top: $header_height;
  border-bottom: 1px solid $border-default;
}

.imageWrapper {
  width: 100%;
  height: 60px;

  @media screen and (min-width: $breakpoint_l) {
    height: 104px;
  }
}

.image {
  width: 100%;
  height: 0px;

  @media screen and (min-width: $breakpoint_l) {
    height: 104px;
  }
}

.title {
  @include text-h5;

  color: $lights-high;
  position: absolute;
  text-transform: uppercase;
  padding: $spacing-4;

  @media screen and (min-width: $breakpoint_l) {
    @include text-h2;
    padding: $spacing-5 $spacing-6;
  }
}
